
import { IonGrid, IonCol, IonRow, IonText } from "@ionic/vue";
import { defineComponent } from "vue";
import { territorios } from "@/modules/cyanRegions";
import unreact from "@/modules/unreact";

interface ElementoMapa {
  id: number;
  name: string;
  route: string;
}

export default defineComponent({
  name: "CentroPicker",
  components: {
    IonGrid,
    IonCol,
    IonRow,
    IonText
  },
  data() {
    return {
      seed: unreact(this.seedCP + "") ?? ""
    };
  },
  computed: {
    whereWeAre: function(): string {
      const t = (territorios as any)[this.seed] || {};
      return (t && t.nombre) ? t.nombre + ", " + t.nombrePadre : "";
    },
    centros: function(): ElementoMapa[] {
      const _acj = (this as any).$store.state.allowedCentrosJuntas;

      const fracaso = [
        {
          id: -1,
          name: "",
          route: "/home"
        }
      ];

      if (!(this.seed in _acj)) {
        return fracaso;
      }

      const acj = _acj[this.seed];

      const centros = this.onlyFull ? acj.centrosFull : acj.centros;

      if (centros.length == 0) return fracaso;

      const r: ElementoMapa[] = [];
      let idx;
      let id = 1;

      for (idx of centros) {

        let route;
        const dc = acj.datosCentros[idx];

        if (this.ifFull != "" && dc.isFull) {
          route = this.ifFull + this.seed + '/' + idx;
        } else if (this.ifSingleJunta != '' && acj.mapaCentrosJuntas[idx].length == 1) {
          route = this.ifSingleJunta + this.seed + '/' + acj.mapaCentrosJuntas[idx][0];
        } else {
          route = this.nextRoute + this.seed + '/' + idx;
        }

        r.push({
          id: id++,
          name: dc.nombre,
          route
        });
      }

      return r;
    }
  },
  props: {
    seedCP: {
      type: String,
      default: "SV"
    },
    nextRoute: {
      type: String
    },
    onlyFull: {
      type: Boolean,
      default: false
    },
    ifFull: {
      type: String,
      default: ""
    },
    ifSingleJunta: {
      type: String,
      default: ""
    }
  },
  methods: {}
});
